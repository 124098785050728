// import function to register Swiper custom elements
import Swiper from "swiper";
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { Scrollbar, Navigation, A11y } from "swiper/modules";
import { Accordions } from "./Accordions";
import { Chain } from "./Chain";
import { BluePrint } from "./BluePrint";

document.addEventListener("DOMContentLoaded", (event) => {
  const forms = document.querySelectorAll(".form");
  if (forms) {
    forms.forEach((singoloForm) => {
      const onSubmit = function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const f = singoloForm as HTMLFormElement;
        const fd = new FormData(f);

        //@ts-ignore
        grecaptcha.ready(function () {
          //@ts-ignore
          grecaptcha
            .execute("6Le24nooAAAAAFMiJXjr2I6bQCu8blgdyRof6ZRt", {
              action: "submit",
            })
            .then(function (token) {
              console.log("token", token);
              fd.append("g-recaptcha-response", token);
              fetch(f.action, {
                method: "POST",
                body: fd,
              })
                .then((res) => res.json())
                .then((result) => {
                  f.reset();
                  window.alert(result.message);
                })
                .catch((error) => {
                  window.alert(error.message);
                });
            })
            .catch((err) => console.error(err));
        });
        return false;
      };
      const formBtn = singoloForm.querySelector("button.form-button");
      if (formBtn) formBtn.addEventListener("click", onSubmit);

      singoloForm.addEventListener("submit", (ev) => {
        ev.preventDefault();
        return false;
      });
    });
  }

  const s = new Swiper(".swiper-normal", {
    modules: [Navigation],
    navigation: {
      enabled: true,
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    speed: 500,
    autoplay: true,
    centeredSlides: true,
  });
  const scroll = new Swiper(".swiper-scroll", {
    modules: [Scrollbar, A11y],
    scrollbar: {
      draggable: true,
      enabled: true,
      el: ".swiper-scrollbar",
      dragSize: 29,
    },
    spaceBetween: 8,
    autoHeight: true,
    slidesPerView: "auto",
  });

  const accordionsDiv = document.querySelectorAll(".t-accordion");
  if (accordionsDiv) {
    accordionsDiv.forEach((acDiv: Element) => {
      const el = acDiv as HTMLElement;
      const id = el.dataset.containerId;
      ReactDOM.createRoot(acDiv).render(
        <React.StrictMode>
          <Accordions id={id || ""} />
        </React.StrictMode>
      );
    });
  }

  const chainDiv = document.querySelectorAll(".chain");
  if (chainDiv) {
    chainDiv.forEach((acDiv: Element) => {
      const el = acDiv as HTMLElement;
      const id = el.dataset.containerId;
      ReactDOM.createRoot(acDiv).render(
        <React.StrictMode>
          <Chain id={id || ""} />
        </React.StrictMode>
      );
    });
  }

  const bluePrintDiv = document.querySelectorAll(".piantina");
  if (bluePrintDiv) {
    bluePrintDiv.forEach((acDiv: Element) => {
      const el = acDiv as HTMLElement;
      const id = el.dataset.containerId;
      ReactDOM.createRoot(acDiv).render(
        <React.StrictMode>
          <BluePrint id={id || ""} />
        </React.StrictMode>
      );
    });
  }

  //@ts-ignore
  const idXTContainer = window.idXTContainer;
  if (idXTContainer) {
    const navItems = document.querySelectorAll(
      ".navItem[data-containerid='" + idXTContainer + "']"
    );
    console.log(".navItem[data-containerid='" + idXTContainer + "']");
    if (navItems) {
      navItems.forEach((item) => {
        item.classList.add("clicked");
      });
    }
  }
  /*
const navItems = document.querySelectorAll(".navItem");
const title = document.getElementById("title");
if (navItems && title) {
  navItems.forEach((item) => {
    const t = title.innerHTML.toLowerCase();
    const n = item.innerHTML.toLowerCase();
    if (t == n) {
      const i = item as HTMLElement;
      i.classList.add("clicked");
    }
  });
}
*/
});
