import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";

export function Accordions(props: { id: string }) {
  const u = "/Apps/WebObjects/Tornieri.woa/wa/datiAccordion?id=" + props.id;
  const [colLeft, setColLeft] = useState<any[]>([]);
  const [colRight, setColRight] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>();

  useEffect(() => {
    if (props.id) {
      fetch(u)
        .then((res) => res.json())
        .then((result) => {
          var a = [];
          var b = [];

          for (var i = 0; i < result.records.length; i++) {
            if (i % 2 == 0) {
              //@ts-ignore
              a.push(result.records[i]);
            } else {
              //@ts-ignore
              b.push(result.records[i]);
            }
          }
          setColLeft(a);
          setColRight(b);
          //setItems(result.records);
        })
        .catch((er) => {
          console.error(er);
        });
    }
  }, [props.id]);
  return (
    <>
      <div className="bg-darkBlue text-white font-light h-min md:grid md:grid-cols-2">
        <div>
          {colLeft
            ? colLeft.map((item: any, k: any) => {
                const show =
                  selectedItem !== undefined && selectedItem.id === item.id;
                return (
                  <button
                    key={k}
                    className="odd:bg-darkBlue even:bg-grayBlue  w-full block relative shadow-sm text-left px-4 py-3 md:px-11 md:py-5"
                    onClick={() => {
                      if (show) setSelectedItem(undefined);
                      else setSelectedItem(item);
                    }}
                  >
                    <div className="z-20">
                      <div className="text-sm leading-[17px] tracking-[0px] sm:text-[16px] sm:leading-[19px]">
                        {item.title}
                      </div>
                      {show ? (
                        <BsArrowDownRight className="absolute top-3 right-4 w-4 h-4 sm:w-7 sm:h-7 sm:right-11 sm:top-4" />
                      ) : (
                        <BsArrowUpRight className="absolute top-3 right-4 w-4 h-4 sm:w-7 sm:h-7 sm:right-11 sm:top-4" />
                      )}
                    </div>
                    <Transition
                      enter="duration-500 transition-opacity"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-500 transition-opacity"
                      leaveTo="opacity-0"
                      leaveFrom="opacity-100"
                      show={show}
                      className="bg-inherit absolute top-10 left-0 z-40 w-full px-12 pt-8 pb-7 shadow-xl "
                    >
                      <div className="text-lightBlue font-bold text-[18px] leading-[21px] tracking-[0px] sm:text-[20px] sm:leading-[24px]">
                        {item.subtitle}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                        className="mt-4 text-white prose text-sm leading-[17px] tracking-[0px] sm:text-[16px] sm:leading-[19px]"
                      ></div>
                    </Transition>
                  </button>
                );
              })
            : ""}
        </div>
        <div>
          {colRight
            ? colRight.map((item: any, k: any) => {
                const show =
                  selectedItem !== undefined && selectedItem.id === item.id;
                return (
                  <button
                    key={k}
                    className="odd:bg-darkBlue even:bg-grayBlue w-full block relative shadow-sm text-left px-4 py-3 md:px-11 md:py-5 md:even:bg-darkBlue md:odd:bg-grayBlue"
                    onClick={() => {
                      if (show) setSelectedItem(undefined);
                      else setSelectedItem(item);
                    }}
                  >
                    <div className="z-20">
                      <div className="text-sm leading-[17px] tracking-[0px] sm:text-[16px] sm:leading-[19px]">
                        {item.title}
                      </div>
                      {show ? (
                        <BsArrowDownRight className="absolute top-3 right-4 w-4 h-4 sm:w-7 sm:h-7 sm:right-11 sm:top-4" />
                      ) : (
                        <BsArrowUpRight className="absolute top-3 right-4 w-4 h-4 sm:w-7 sm:h-7 sm:right-11 sm:top-4" />
                      )}
                    </div>
                    <Transition
                      enter="duration-500 transition-opacity"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-500 transition-opacity"
                      leaveTo="opacity-0"
                      leaveFrom="opacity-100"
                      show={show}
                      className="bg-inherit absolute top-10 left-0 z-40 w-full px-12 pt-8 pb-7 shadow-xl "
                    >
                      <div className="text-lightBlue font-bold text-[18px] leading-[21px] tracking-[0px] sm:text-[20px] sm:leading-[24px]">
                        {item.subtitle}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                        className="mt-4 text-white prose text-sm leading-[17px] tracking-[0px] sm:text-[16px] sm:leading-[19px]"
                      ></div>
                    </Transition>
                  </button>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
}
