import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

function Counter(props: { max: any }) {
  const [counter, setCounter] = useState(0);
  const max = parseInt(props.max);
  var x = 500 / (max / 10);
  x = max > 500 ? 10 : x;
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  var c = 1;
  if (max > 500) {
    c = max / 500;
    c = Math.floor(c);
  }

  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        setCounter((counter) => {
          if (counter < max) return counter + c;
          else clearInterval(interval);
          return max;
        });
      }, x);

      return () => {
        clearInterval(interval);
      };
    }
  }, [inView]);

  return (
    <div className="Counter" ref={ref}>
      {counter}
    </div>
  );
}

export function Chain(props: { id: string }) {
  const u = "/Apps/WebObjects/Tornieri.woa/wa/datiChain?id=" + props.id;
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    console.log("useEffect", props.id);
    if (props.id) {
      fetch(u)
        .then((res) => res.json())
        .then((result) => {
          const r = [...result.records];
          setCounter(r[0].counter);
          r.splice(0, 1);
          setItems(r);
        })
        .catch((er) => {
          console.error(er);
        });
    }
  }, [props.id]);

  const [counter, setCounter] = useState<boolean>(false);

  return (
    <div className=" overflow-hidden overflow-x-auto">
      <div className="flex flex-row gap-2 bg-darkBlue w-fit mx-auto px-4 mb-2 sm:px-9">
        {items.map((item: any, k: any) => {
          return (
            <div
              key={k}
              className={classNames(
                k % 2 == 0 ? "bg-grayBlue " : "bg-blue",
                k !== 0 ? "chainLink" : "",
                "text-white h-[110px] w-[176px] py-6 flex flex-col justify-center relative sm:w-[251px] sm:h-[166px]"
              )}
            >
              <h2 className="mt-2 text-[22px] leading-[27px] tracking-[0px] text-center font-bold sm:text-[31px] sm:leading-[37px] pt-">
                <span className="flex flex-row gap-2 w-fit mx-auto">
                  {counter ? <Counter max={item.title} /> : <>{item.title}</>}
                  {item.spec}
                </span>
              </h2>
              <div className="text-[11px] leading-[13px] tracking-[0.11px] font-light max-w-[92px] mx-auto mb-1 text-center mt-2 sm:max-w-[150px] sm:text-[13px] sm:leading-[17px] sm:tracking-[0.13px]">
                {item.intro}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
