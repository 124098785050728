import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

export function BluePrint(props: { id: string }) {
  const u = "/Apps/WebObjects/Tornieri.woa/wa/datiPiantina?id=" + props.id;
  const [items, setItems] = useState<any[]>([]);
  const [bluePrint, setBluePrint] = useState();
  const [bluePrintMobile, setBluePrintMobile] = useState();
  const [open, setOpen] = useState();

  useEffect(() => {
    console.log("useEffect", props.id);
    if (props.id) {
      fetch(u)
        .then((res) => res.json())
        .then((result) => {
          const r = [...result.records];
          setBluePrint(r[0].bluePrint);
          setBluePrintMobile(r[0].bluePrintMobile);
          r.splice(0, 1);
          setItems(r);
        })
        .catch((er) => {
          console.error(er);
        });
    }
  }, [props.id]);
  console.log("bg", bluePrint);
  return (
    <>
      {items ? (
        <div className="bg-darkBlue pb-5 mx-auto">
          <div className="px-28">
            <div
              style={{
                content: "",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url('" + bluePrint + "')",
              }}
              className="hidden md:block pt-[45%] relative"
            >
              {items.map((item: any, k: any) => {
                if (!item.emptyCoords) {
                  const showCOntent = open === k;
                  return (
                    <div key={k}>
                      <button
                        style={{ top: item.posY + "%", left: item.posX + "%" }}
                        className="absolute pin"
                        onClick={() => {
                          if (open !== k) {
                            setOpen(k);
                          } else {
                            setOpen(undefined);
                          }
                        }}
                      ></button>
                      <Transition
                        enter="duration-500 transition-opacity"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="duration-500 transition-opacity"
                        leaveTo="opacity-0"
                        leaveFrom="opacity-100"
                        show={showCOntent}
                        className="bg-white p-4 z-30 text-darkBlue w-64 absolute right-0 top-0 translate-x-[1/3] translate-y-1/4 shadow-xl"
                      >
                        <div className="relative">
                          <button className="-right-1 absolute -top-1"
                            onClick={() => {
                              setOpen(undefined);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                          <div className="font-bold text-xl text-center">
                            {item.title}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                            className="font-light mt-4 text-sm text-darkBlue prose"
                          />
                        </div>
                      </Transition>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="px-9">
            <div
              style={{
                content: "",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundImage: "url('" + bluePrintMobile + "')",
              }}
              className="pt-[222.0472%] relative md:hidden"
            >
              {items.map((item: any, k: any) => {
                if (!item.emptyCoords) {
                  var showContent = open === k;
                  return (
                    <div key={k}>
                      <button
                        style={{
                          left: 100 - item.posY + "%",
                          top: item.posX + "%",
                        }}
                        className="absolute pin"
                        onClick={() => {
                          if (open !== k) {
                            setOpen(k);
                          } else {
                            setOpen(undefined);
                          }
                        }}
                      ></button>
                      <Transition
                        enter="duration-500 transition-opacity"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="duration-500 transition-opacity"
                        leaveTo="opacity-0"
                        leaveFrom="opacity-100"
                        show={showContent}
                        className="bg-white p-4 z-30 text-darkBlue w-64 absolute right-0 top-0 shadow-xl"
                      >
                        <div className="relative">
                          <button className="-right-1 absolute -top-1"
                            onClick={() => {
                              setOpen(undefined);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                          <div className="font-bold text-xl text-center">
                            {item.title}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                            className="font-light mt-4 text-darkBlue text-sm prose"
                          />
                        </div>
                      </Transition>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
